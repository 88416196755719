<template>
  <div v-if="Object.keys(profileData).length" id="custom-user-profile">
   
    <profile-header :header-data="profileData" />
    <!-- profile info  -->
    <section id="profile-info">
      <b-row>
        <!-- about suggested page and twitter feed -->
        <b-col lg="3" cols="12" order="2" order-lg="1">
          <profile-about :about-data="profileData" />
          <profile-suggested-pages :pages-data="profileData.suggestedPages" />
          <profile-twitter-feed :twitter-feed="profileData.twitterFeeds" />
        </b-col>
        <!--/ about suggested page and twitter feed -->

        <!-- post -->
        <b-col lg="6" cols="12" order="1" order-lg="2">
          <profile-post :posts="profileData.post" />
        </b-col>
        <!-- post -->

        <!-- latest photos suggestion and polls -->
        <b-col lg="3" cols="12" order="3">
          <profile-latest-photos :latest-images="profileData.latestPhotos" />
          <profile-suggestion :suggestions="profileData.suggestions" />
          <profile-polls :polls-data="profileData.polls" />
        </b-col>
        <!--/ latest photos suggestion and polls -->

        <!-- load more  -->
        <b-col cols="12" order="4">
          <profile-bottom />
        </b-col>
        <!--/ load more  -->
      </b-row>
    </section>
    <!--/ profile info  -->
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import ProfileHeader from './ProfileHeader.vue'
import ProfileAbout from './ProfileAbout.vue'
import ProfileSuggestedPages from './ProfileSuggestedPages.vue'
import ProfileTwitterFeed from './ProfileTwitterFeed.vue'
import ProfilePost from './ProfilePost.vue'
import ProfileLatestPhotos from './ProfileLatestPhotos.vue'
import ProfileSuggestion from './ProfileSuggestion.vue'
import ProfilePolls from './ProfilePolls.vue'
import profileBottom from './profileBottom.vue'
import router from '@/router'


import { getUserById } from '@/amic-js/users'
import { getSmallThumbnail, getMediumThumbnail } from '@/amic-js/stringutils'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

/* eslint-disable global-require */
export default {
  components: {
    BRow,
    BCol,

    ProfileHeader,
    ProfileAbout,
    ProfileSuggestedPages,
    ProfileTwitterFeed,
    ProfilePost,
    ProfileLatestPhotos,
    ProfileSuggestion,
    ProfilePolls,
    profileBottom,
  },
  methods: {
    getUserById,
    getSmallThumbnail,
    getMediumThumbnail,
  },
  data() {
    return {
      userId: '',
      profileData: {},
    }
  },
  setup() {
    const toast = useToast();
    return { toast }
  },
  created() {
    this.userId = router.currentRoute.params.id
    if (router.currentRoute.params.id != null) {
      getUserById(router.currentRoute.params.id).then((user) => {
        var userImageURL = ""
        var userImageThumbnailURL = ""
        if (user.get("userImage")) {
          userImageURL = user.get("userImage").url();
          userImageThumbnailURL = getMediumThumbnail(userImageURL);
        }
        var createdDate = user.get("createdAt").toISOString().slice(0, 10)
        var createdTime = user.get("createdAt").toISOString().slice(11, (user.get("createdAt").toISOString().length) - 5)
        var isEmailVerified;
        if (user.get("emailVerified") === undefined) {
          isEmailVerified = 'no-email'
        }
        else {
          isEmailVerified = user.get("emailVerified")
        }
        this.profileData = {
          id: user.id,
          avatar: userImageURL,
          avatarThumbnail: userImageThumbnailURL,
          createdAt: createdDate + "@" + createdTime,
          username: user.get("username"),
          fullName: user.get("firstName") + " " + user.get("lastName"),
          bio: user.get("bio"),
          post: 'Nuclear Power Engineer',
          plan: isEmailVerified,
          email: user.get("email") ? user.get("email") : "",
          emailVerified: isEmailVerified,
          phone: user.get("phone") ? '+' + user.get("phone") : "",
          authProvider: user.get("authData") ? user.get("authData")[0] : '',

          birthday: user.get("userInformation").get("userInformationBirthday").get("birthday").toISOString().slice(0, 10),
          sex: user.get("userInformation").get("userInformationSex").get("sex"),
          country: user.get("userInformation").get("userInformationCountry").get("country"),
          city: user.get("userInformation").get("userInformationCity").get("city"),
          email2: user.get("userInformation").get("userInformationEmail").get("email"),
          phone2: user.get("userInformation").get("userInformationPhone").get("phone"),

        }
      }, (error) => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list ' + error.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
          {
            timeout: false,
          })
      })
    }
    else {
      this.toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching users list: User id not found ',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      },
        {
          timeout: false,
        })
    }
    //this.$http.get('/profile/data').then(res => { this.profileData = res.data })
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';
</style>
